module controllers {
    export module forex {
        interface IForexListCtrlScope extends ng.IScope { }

        interface IForexListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class forexListCtrl {
            static $inject = [
                "$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "forexService",
                "$state",
                "$stateParams",
                "$transitions",
                "statusService",
                "classificationValueService",
                "supplierService",
                "customerService",
                "currencyService"
            ];

            forexSearch: interfaces.forex.IForexSearch = {
                statusId: -1,
            };

            IsLoading: boolean = false;
            ShowGrid: boolean = false;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.forex.IForexDisplay>;
            searchAccordian: boolean = false;
            myHook: any;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25,
            };

            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;

            fromMaturityDateOpen: boolean = false;
            toMaturityDateOpen: boolean = false;

            ctrlName: string;

            sortName: string;
            sortDirection: string;

            dropdownsObject = {
                ForexStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            ownerEntityId: number;

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.forex.IForexDisplay> = [];

            filterNames: string[] = [
                "EntityCode",
                "Code",
                "TypeDescription",
                "ContractBalance",
                "ForexDate",
                "CurrencyCode",
                "MaturityEndDate",
                "ExchangeRate",
                "IssuedNumber",
                "ResponsibleUserFullName",
                "StatusDescription",
                "BankName",
            ];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            constructor(
                private $scope: IForexListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private forexService: interfaces.forex.IForexService,
                private $state: ng.ui.IStateService,
                private $stateParams: IForexListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private currencyService: interfaces.applicationcore.ICurrencyService
            ) {
                this.loadControls();
                this.myHook = $transitions.onSuccess(
                    {
                        to: "auth.Forex",
                        from: "auth.Forex.**",
                    },
                    () => {
                        this.loadForex();
                    }
                );
            } //end constructor

            loadControls() {
                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadStatuses()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });
             }

             loadStatuses() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({                   
                    type: Enum.EnumStatusType.ForexContract 
                }, (result) => {

                    this.statusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

             getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            LoadForexTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.FECType
                }, (resultList) => {
                       
                }).$promise;
            }


            loadForex() {
                this.bsLoadingOverlayService.wrap(
                    {
                        referenceId: "forex",
                    },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: [],
                        };

                        angular.forEach(this.forexSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({
                                        Name: n.name,
                                        Value: n.filters[0].term,
                                    });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority,
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            entityCode: this.entity,
                            pageNumber: this.paginationOptions.pageNumber,
                        };

                        return this.forexService.getList().query(
                            params,
                            (data) => {
                                this.IsLoading = false;
                                this.gvwList.data = data;
                                this.selectedRowsArray = [];

                                if (data[0]) {
                                    this.gvwList.totalItems = data[0].NumberRecords;
                                } else {
                                    this.gvwList.totalItems = 0;
                                }
                                this.$timeout(() => {
                                    if (this.apiList) {
                                        this.getFilters();
                                        this.setFilters();
                                    }
                                });
                            },
                            (errorResponse) => {
                                this.generalService.displayMessageHandler(
                                    <interfaces.applicationcore.IMessageHandler>errorResponse.data
                                );
                            }
                        ).$promise;
                    }
                );
            } //end loadForex

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (
                        this.apiList.grid.getColumn(name).filters[0].term ||
                        this.apiList.grid.getColumn(name).filters[0].term == ""
                    ) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(
                            this.filterList,
                            _.matchesProperty("key", name)
                        );

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => {
                                return o.key === name;
                            });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term,
                            };
                        } else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term,
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(
                        this.filterList,
                        (keyValue: interfaces.applicationcore.IKeyValue) => {
                            this.apiList.grid.getColumn(keyValue.key).filters[0].term =
                                keyValue.value;
                        }
                    );
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(
                        this.sortName
                    ).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }

            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                this.forexSearch = {
                    statusId: -1
                }
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.forexSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    entityCode: this.entity,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.forexService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumberRecords;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;

                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                Exists = 1;
                            }
                        }

                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }
                    }
                });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }


            editForex(fecId: number)
            {
                this.$state.go("auth.Forex.Update", { fecId: fecId });
            }

            /*
             filterNames: string[] = [
                "EntityCode",
                "Code",
                "TypeDescription",
                "ContractBalance",
                "ForexDate",
                "CurrencyCode",
                "MaturityEndDate",
                "ExchangeRate",
                "IssuedNumber",
                "ResponsibleUserFullName",
                "StatusDescription",
                "BankName",
            ];
            */

            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)

                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button"  ng-click="grid.appScope.forexListCtrl.editForex(row.entity.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 60,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 100,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                }, {
                    name: "ForexDate",
                    displayName: "Date",
                    field: "ForexDate",
                    width: 130,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="PaymentDate"></gts-date>
                    </div>`,
                },{
                    name: "TypeDescription",
                    displayName: "Type",
                    field: "TypeDescription",
                    width: 100,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                },
                {
                    name: "ContractBalance",
                    displayName: "Balance",
                    field: "ContractBalance",
                    width: 100,
                    cellClass: 'text-right',
                    cellFilter: "number: 2",
                    enableFiltering: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 90,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "MaturityEndDate",
                    displayName: "Maturity Date",
                    field: "MaturityEndDate",
                    width: 130,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="ValueDate"></gts-date>
                            </div>`,
                },
                {
                    name: "ExchangeRate",
                    displayName: "Exchange Rate",
                    field: "ExchangeRate",
                    width: 120,
                    cellClass: 'text-right',
                    cellFilter: "number: 2",
                    enableFiltering: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            
                },
                {
                    name: "IssuedNumber",
                    displayName: "Contract Number",
                    field: "IssuedNumber",
                    width: 130,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                },
                {
                    name: "ResponsibleUserFullName",
                    displayName: "Responsible User",
                    field: "ResponsibleUserFullName",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "StatusDescription",
                    displayName: "Status",
                    field: "StatusDescription",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "BankName",
                    displayName: "Bank",
                    field: "BankName",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }                
                ]
            };


            createForex(){
                this.$state.go("auth.Forex.Create", {});
            }

            printDocuments() {
                if (this.apiList && this.apiList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiList.selection.getSelectedRows()[0];

                    this.$state.go("auth.Forex.Update.Documents", { fecId: selected.Id });

                } else {
                    this.generalService.displayMessage("Please select a Forex Contract", Enum.EnumMessageType.Warning);
                }
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.forexSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    entityCode: this.entity,
                }

                this.forexService.getForexExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            

        } //end class forexListCtrl

        angular.module("app").controller("forexListCtrl", controllers.forex.forexListCtrl);
    } //end module forex
} //end module controllers
